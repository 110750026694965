<template>
  <b-form-group :label-sr-only="!showLabel" :label-for="inputName" :invalid-feedback="veeErrors.first(inputName)">
    <template slot="label">{{ label }} <span v-if="required" class="text-danger">*</span></template>
    <b-form-input
      :name="inputName"
      :value="value"
      @input="onInput"
      v-validate="`${this.required ? 'required' : ''}|digits:5`"
      :state="validateState()"
      :aria-describedby="`${inputName}-feedback`"
      :data-vv-as="errorMessage"
      :placeholder="placeholder"
      :disabled="disabled"
      v-mask="'#####'"
      trim
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'ZipInput',
  props: {
    name: {
      type: String,
      required: true
    },
    value: String,
    label: String,
    required: Boolean,
    showLabel: {
      type: Boolean,
      default: true
    },
    placeholder: String,
    disabled: Boolean,
    province: String
  },
  data() {
    return {};
  },
  computed: {
    inputName() {
      return `input-${this.name}`;
    },
    errorMessage() {
      if (this.label) {
        return this.label.toLowerCase();
      }

      return '';
    }
  },
  methods: {
    validateState() {
      if (
        this.veeFields[this.inputName] &&
        (this.veeFields[this.inputName].dirty || this.veeFields[this.inputName].validated)
      ) {
        return !this.veeErrors.has(this.inputName);
      }

      return null;
    },
    isValid(silent = false) {
      this.$validator.validate(null, null, { silent: silent });

      return this.veeFields[this.inputName]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
