<template>
  <div>
    <GenericSearch :value="search" @updateSearch="updateSearch" class="mr-2 mb-12 max-w-32" />
    <GenericTable
      :columns="columns"
      :data="data"
      :actions="true"
      :loading="loading"
      :pagination="pagination"
      @updateCurrentPage="updateCurrentPage"
      @sort="sort"
    >
      <template #actions="{ row }">
        <div class="action-button">
          <UpdatePlayerModal @playerUpdated="playerUpdated()" v-if="row.id" :player="row" class="ml-4" />
        </div>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import PlayerServiceV2 from '@/lib/player-service-v2';

import GenericTable from '@/components/GenericTable.vue';
import GenericSearch from '@/components/GenericSearch.vue';
import UpdatePlayerModal from '../../components/modals/UpdatePlayerModal.vue';

export default {
  name: 'Players',
  components: {
    GenericTable,
    GenericSearch,
    UpdatePlayerModal
  },
  data() {
    return {
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'lastName',
          label: 'Last Name',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'email',
          label: 'Email',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'city',
          label: 'City',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'province',
          label: 'Province',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'phone',
          label: 'Phone',
          sortable: false,
          classes: 'w-auto'
        }
      ],
      data: [],
      pagination: {
        page: 0,
        pageSize: 10,
        sortBy: 'firstName',
        sortDir: 'desc',
        total: 0
      },
      errorMessage: null,
      loading: true,
      search: null,
      isRbAdmin: false
    };
  },
  async created() {
    this.loading = true;
    await this.fetchData();
    this.loading = false;
  },
  async mounted() {
    this.loading = true;
    const sessionUser = await getAuth().sessionUser();
    this.organizationId = sessionUser.organizationUuid;
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.clearSearch();
    await this.fetchData();
    this.loading = false;
  },
  methods: {
    async updateSearch(search) {
      this.search = search;
      await this.updateCurrentPage(0);
    },
    clearSearch() {
      this.search = null;
    },
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          search: this.search,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortBy: this.pagination.sortBy,
          sortDir: this.pagination.sortDir
        };

        const result = await PlayerServiceV2.listPlayers(params);

        this.data = result.data;
        this.pagination = result.pagination;
      } catch (error) {
        this.errorMessage = this.parseError(error);
      } finally {
        this.loading = false;
      }
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.fetchData();
    },
    async sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      await this.fetchData();
    },
    async playerUpdated() {
      await this.fetchData();
    }
  }
};
</script>
