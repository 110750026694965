<template>
  <div class="input-country">
    <b-form-group label-for="input-country" :invalid-feedback="veeErrors.first('input-country')" class="mb-4">
      <template slot="label">Country<span class="text-danger" v-if="required"> *</span></template>
      <b-form-select
        name="input-country"
        v-model="country"
        v-validate="`${required ? 'required' : ''}`"
        :state="validateState('input-country')"
        :options="countryOptions"
        data-vv-as="country"
      >
        <template #first>
          <b-form-select-option :value="null">-- Please select a Country --</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  inject: ['parentValidator'],
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  },
  created() {
    this.$validator = this.parentValidator;
  },
  computed: {
    country: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      countryOptions: [
        {
          value: 'CA',
          text: 'Canada'
        },
        {
          value: 'US',
          text: 'United States'
        }
      ]
    };
  }
};
</script>

<style>
.input-country .form-group {
  margin-bottom: 1rem;
}
.input-country .form-group label {
  display: block;
}
.input-country .form-group select {
  background-color: #ffffff;
}
.input-country .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.2rem + 2px);
  padding: 0.6rem calc(1.5em + 1.2rem) 0.6rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 1rem center/8px 10px no-repeat;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.input-country .form-control.is-invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.input-country .form-control.is-valid {
  border-color: #56b65e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2356b65e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.input-country select[disabled='disabled'] {
  background-color: #eeeeee;
}
</style>
