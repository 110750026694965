<template>
  <b-form-group :label-for="inputName" :description="description" :invalid-feedback="veeErrors.first(inputName)">
    <template slot="label">{{ label }} <span v-if="required" class="text-danger">*</span></template>
    <b-form-input
      :name="inputName"
      :value="value"
      @input="onInput"
      v-validate="validationRules"
      :state="validateState()"
      :aria-describedby="`${inputName}-feedback`"
      :data-vv-as="errorMessage"
      :placeholder="placeholder"
      :disabled="disabled"
      trim
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'TextInput',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    label: String,
    required: Boolean,
    showLabel: {
      type: Boolean,
      default: true
    },
    placeholder: String,
    disabled: Boolean,
    description: {
      type: String,
      required: false
    },
    min: String,
    max: String,
    numeric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    inputName() {
      return `input-${this.name}`;
    },
    errorMessage() {
      if (this.label) {
        return this.label.toLowerCase();
      }

      return '';
    },
    validationRules() {
      const rules = {};

      if (this.required) {
        rules['required'] = true;
      }
      if (this.numeric) {
        rules['numeric'] = true;
      }
      if (this.min) {
        rules['min'] = this.min;
      }
      if (this.max) {
        rules['max'] = this.max;
      }

      return rules;
    }
  },
  methods: {
    validateState() {
      if (
        this.veeFields[this.inputName] &&
        (this.veeFields[this.inputName].dirty || this.veeFields[this.inputName].validated)
      ) {
        return !this.veeErrors.has(this.inputName);
      }

      return null;
    },
    isValid(silent = false) {
      this.$validator.validate(null, null, { silent: silent });

      return this.veeFields[this.inputName]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
