import Axios from '@/axios';

const playerUrl = `/player-admin-service/v2/players`;

const listPlayers = async (params = {}) => {
  const response = await Axios.get(playerUrl, {
    params
  });

  return response.data.data;
};

const updatePlayer = async (id, body) => {
  const response = await Axios.patch(`${playerUrl}/${id}`, body);
  return response.data.data;
};

export default {
  listPlayers,
  updatePlayer
};
